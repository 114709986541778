import React from 'react'
import ReactStars from "react-rating-stars-component"
//import { Link } from "gatsby"
import { StaticImage }  from "gatsby-plugin-image"
// import Opinions from "../../images/opinions.svg"
import './style.scss'
//import Favicon from '../../images/favicon.png'


const Revs = () => {
  function Img1() {
    return <StaticImage src="../../images/o1.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img2() {
    return <StaticImage src="../../images/o2.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img3() {
    return <StaticImage src="../../images/o3.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }

  function Img4() {
    return <StaticImage src="../../images/o4.jpg"
      alt=""
      placeholder="blurred"
      layout="fixed"
      style={{
         gridArea: "1/1",
         maxHeight: 100,
         maxWidth: 100,
       }}
      formats={["auto", "webp", "avif"]}
      loading="lazy"
       />
  }
  return (
    <>
    <section id="reviews">
    <div className="container-fuild" style={{backgroundColor:'#E8D3D0',
    justifyContent:'center',textAlign:'center',margin:'0',padding:'2em 1em'}}>
    <br />
    <h2 className="title spec-h">What people say about shroom drinks?</h2>
    <br />
    <div className="row" style={{width:'100%',textAlign:'center',display:'inline-flex',flexWrap:'wrap',justifyContent:'center'}}>
      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img4()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>A delicious drink that pleasantly surprised me with its health benefits. A refreshing departure from water, adding a fun twist to my day.</i></p>
      <p style={{fontSize:'1em'}}><b>Olek Rajewski</b>,<br /><sub>Artist</sub></p>
      <a style={{fontSize:'1em'}} href="https://www.instagram.com/oleksynolka/" target="_blank" rel="noopener noreferrer" className="text-dark">@oleksynolka</a>
      <br />
      </div>


      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img3()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>This drink is pure magic! It helped me unwind and escape from the daily chaos. Like a quick getaway in a bottle.</i></p>
      <p style={{fontSize:'1em'}}><b>PRIYANKA BANERJEE</b>,<br /><sub>CEO AND CO-FOUNDER</sub></p>
      <span style={{fontSize:'1em'}} className="is-primary">DEI consulting</span>
      <br />
      </div>



      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img2()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>As a busy entrepreneur, Shroom was my saving grace. It boosted my focus and kept me energized throughout my hectic schedule.</i></p>
      <p style={{fontSize:'1em'}}><b>Ewa Kucharska</b>,<br /><sub> CEO & founder Cluster Oficess</sub></p>
      <a style={{fontSize:'1em'}} href="https://www.clusteroffices.com/" target="_blank" rel="noopener noreferrer" className="text-dark">www.clusteroffices.com</a>
      <br />
      </div>



      <div className="col card" style={{textAlign:'center',margin:'1em',minWidth:'200px'}}>
      <br />
      {Img1()}
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <ReactStars
         count={5}
         size={27}
         activeColor="#DEA5CB"
         color="#DEA5CB"
       />
      </div>
      <p className="sameh"><i>Our date night with Shroom's drinks was absolutely fantastic! It elevated the experience, making our evening even more special.</i></p>
      <p style={{fontSize:'1em'}}><b>Michaś and Karolina</b>,<br /><sub>Couple</sub></p>
      <span style={{fontSize:'1em'}} className="is-primary">Students</span>
      <br />
      </div>
    </div>
    <br />
    <br />
    <a className="btn btn-dark mush" target="_blank"
    rel="noopener noreferrer"
    style={{color:'white'}}
    href="https://g.page/r/CSpTwe2GJoIuEAI/review">
    Rate our drinks <span role="img" aria-label="Wystaw opinię!">⭐</span>
    </a>
    </div>
    </section>

    </>
  );
};

export default Revs;
