import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51OWhJQKZ9oCbvkxeLkuDde13N84I17etQRoUHsTqzqn7meuKoLjWuyKZmx2CQDrIBzh6fvCsrl65092E6m8jwMPO00es3hfQeG"
);

const PricingTable = () => {
  useEffect(() => {
    // Load the Stripe pricing table script dynamically
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.head.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {/* Placeholder for the pricing table element */}
      <div
        dangerouslySetInnerHTML={{
          __html: `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
  	 <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
  	 <stripe-pricing-table pricing-table-id="prctbl_1OYumvKZ9oCbvkxeC7iroIWu"
  	 publishable-key="pk_live_51OWhJQKZ9oCbvkxeLkuDde13N84I17etQRoUHsTqzqn7meuKoLjWuyKZmx2CQDrIBzh6fvCsrl65092E6m8jwMPO00es3hfQeG">
  	 </stripe-pricing-table>`,
        }}
      />
    </Elements>
  );
};

export default PricingTable;
